const locationButton = document.querySelectorAll('.useLocation');

function onSuccess(position) {
    console.log(position.coords.latitude, position.coords.longitude);
}

function onError(error) {
    console.log(error);
}

locationButton.forEach(btn => {
    btn.addEventListener('click', function() {
        navigator.geolocation.getCurrentPosition(onSuccess, onError);
    })
});