const Handlebars = require('handlebars');

let tourTemplate = {};
let bookingTemplate = {};

const initialiseDropdowns = function(target) {
    const dropdowns = $(target).find('.__select select');
    const datePickers = $(target).find('.__date input');
    const timePickers = $(target).find('.__time input');

    dropdowns.each(function() {
        const dropdownId = $(this).parent();

        $(this).select2({
            selectionCssClass: 'select2-css',
            minimumResultsForSearch: Infinity,
            dropdownParent: dropdownId,
            width: "100%",
        });
    });

    flatpickr(datePickers, {
        altInput: true, // creates new visual input
        altFormat: "d/m/Y", // sets the format to be more friendly, i.e. Thursday 8th April 2021
        dateFormat: "Y-m-d", // sets the format to match server-side data i.e. 2021-04-08
    });

    flatpickr(timePickers, {
        enableTime: true,
        static: true,
        noCalendar: true,
        time_24hr: true,
        altInput: true, // creates new visual input
        altFormat: "H:i", // sets the format to be more friendly, i.e. Thursday 8th April 2021
        dateFormat: "H:i:S", // sets the format to match server-side data i.e. 2021-04-08
    });
}

$(document).ready(function() {
    // virtual tours
    if ($('#virtualTourTemplate').length) {
        const source = document.querySelector('#virtualTourTemplate').innerHTML;

        tourTemplate = Handlebars.compile(source);

        const context = {
            title: "Title"
        }

        $('.virtualTourTrigger').on('click', processTourModalContent);
    }

    if ($('#modalFormTemplate').length) {
        const source = document.querySelector('#modalFormTemplate').innerHTML;

        bookingTemplate = Handlebars.compile(source);

        const context = {
            developmentName: 'Development Name',
            developmentImage: '',
            developmentImageXl: '',
            developmentImageLg: '',
            developmentImageMd: '',
            developmentImageSm: '',
            sharedOwnershipPrice: 80000,
            privateSalePrice: 150000,
            minBedrooms: 1,
            maxBedrooms: 3,
        }

        $('.modalFormTrigger').on('click', processModalFormContent);    
    }
});

function processTourModalContent()
{

    context = JSON.parse(this.dataset.modalcontent);

    const html = tourTemplate(context);

    // get the element to set the new HTML into
    const destination = document.querySelector(".virtualTourModal");

    // set the new HTML
    destination.innerHTML = html;
}

function processModalFormContent()
{

    context = JSON.parse(this.dataset.modalcontent);

    const html = bookingTemplate(context);

    // get the element to set the new HTML into
    const destination = document.querySelector(".modalForm");

    // set the new HTML
    destination.innerHTML = html;

    initialiseDropdowns(destination);
}