import A11yDialog from 'a11y-dialog'

const addBodyClass = function(el) {
    el.on('show', function (element, event) {
        $('body').addClass('__modal')
        // Do something on show
    })
    el.on('hide', function (element, event) {
        $('body').removeClass('__modal')
        // Do something on hide
    })
}

$('.modalContainer').each(function(){
    var id = $(this).attr('id'); // make sure your modalContainer element has a unique ID
    var container = document.getElementById(id)
    var dialog = new A11yDialog(container)
    
    addBodyClass(dialog);
});

// incentive modal
$(document).ready(function() {
    const incentiveModal = document.getElementById('incentiveModal');
    
    if (!incentiveModal) return;

    const dialog = new A11yDialog(incentiveModal)
    addBodyClass(dialog);

    dialog.show();
});