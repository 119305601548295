$(function () {
    var body = $('body');
    var nav = $('.nav');
    var burger = $('.burger');
    var nav_trigger = $('.nav').find('ul li');
    var nav_align = 'right';
    var break_point = 992;
    var browser_width = window.innerWidth;
    var nav_width = 300;
    var nav_wrap = $('.navwrap');
    var search_toggle = $('.searchToggle');
    var mobile_search_wrap = $('.mobile-search-wrap');

    //burger click
    burger.click(function (event) {
        event.preventDefault();
        $(this).toggleClass('__active');
        if (body.hasClass('__mobile')) {
            nav_reset();
        } else {
            search_reset();
            nav_launch();
        }
    });

    var set_burger_text = function(text) {
        burger.html(`
            <span class="burger-toggle"></span>
            ${text}
        `)
    };

    /*nav reset*/
    var nav_reset = function nav_reset() {
        set_burger_text('Menu');
        burger.removeClass('__active');
        body.removeClass('__mobile');
        nav_wrap.removeClass('__active');
        nav_wrap.find('ul').removeClass('__active');
    };

    /*nav launch*/
    var nav_launch = function nav_launch() {
        set_burger_text('Close');
        body.addClass('__mobile');
        nav_wrap.addClass('__active');
    };

    search_toggle.click(function() {
        if (mobile_search_wrap.hasClass('__active')) {
            search_reset();
        } else {
            nav_reset();
            search_launch();
        }
    });

    var search_reset = function() {
        search_toggle.text('Search');
        search_toggle.removeClass('__active');
        mobile_search_wrap.removeClass('__active');
        body.removeClass('__search');
    };

    var search_launch = function() {
        search_toggle.text('Close');
        search_toggle.addClass('__active');
        mobile_search_wrap.addClass('__active');
        body.addClass('__search');
    }

    var nav_ul = $('.nav li > ul');
    var view_button = $('<li class="viewall"><a href="#">View</a></li>');
    
    function initMenu() {
        var browser_width = window.innerWidth;

         // nav dropdown on focus/keyboard navigation
        if (browser_width >= break_point) {
            $('.menu-item').focusin(function () {
                $(this).addClass('__active');
                $(this).children('button').attr('aria-expanded', true);
            });
            $('.menu-item').focusout(function () {
                $(this).removeClass('__active');
                $(this).children('button').attr('aria-expanded', false);
            });
            $('.menu-item').mouseover(function () {
                $(this).children('button').attr('aria-expanded', true);
            });
            $('.menu-item').mouseout(function () {
                $(this).children('button').attr('aria-expanded', false);
            });
        }

        if (browser_width < break_point) {
            nav_trigger.click(function (event) {
                event.stopPropagation();
                if ($(this).children('ul').length > 0) {
                    event.preventDefault();
                    $(this)
                        .children('ul')
                        .slideToggle(500);
                    $(this)
                        .children('a, button')
                        .toggleClass('__active');
                }
            });
        }

        if (browser_width < break_point) {
            nav_ul.each(function() {
                if ($(this).prev('a').length) {
                    $(this).prepend('<li class="viewall"><a href="#">View</a></li>');
                }
            })
        } else {
            if (nav_ul.has(view_button).length) {
                $('.viewall').remove();
            }
            nav_reset();
        }
        

        // Touch device check
        const isTouchDevice = () => {
            return window.matchMedia("(pointer: coarse)").matches
        }
        // If desktop and touch device, disable link click and add extra view link
        if (browser_width > break_point && isTouchDevice()) {
            nav_trigger.click(function (event) {
                event.stopPropagation();
                if ($(this).children('ul').length > 0) {
                    event.preventDefault();
                }
            });
            nav_ul.prepend(view_button);
        }

        var view_all = $('.viewall');
        view_all.each(function () {
            var parent_url = $(this)
                .closest('ul')
                .siblings()
                .attr('href');
            var parent_title = $(this)
                .closest('ul')
                .siblings()
                .html();
            $(this)
                .find('a')
                .attr('href', parent_url);
            $(this)
                .find('a')
                .html('View ' + parent_title);
        });

        view_all.click(function (event) {
            event.stopPropagation();
        });
    }
    
    initMenu();

    $(window).on('resize', function(){
        initMenu();
    });

});